import * as React from 'react';
import Seo from '../components/seo';
import Layout from '../components/layout';
import AboutUs from '../components/AboutUs/AboutUs';
import FeatureSectionLeft from '../components/FeatureSectionLeft/FeatureSectionLeft';

const JetsAboutPage = ({ data, location }) => {
  return (
    <div>
      <Layout>
        <AboutUs
          imageSrc="/corporate/img-business.webp"
          imageAlt="Example image"
          title="会社概要"
          subtitle="OUTLINE"
          colorType="secondary"
          sectionId="outline"
          detailItems={[
            {
              title: '事業所名',
              description: 'JETS社会保険労務士・行政書士事務所',
            },
            {
              title: '事業概要',
              description:
                '補助金・助成金の申請サポート、人材支援、外国人ビザ申請代行など',
            },
            {
              title: '主なお客様',
              description: '中小企業の経営者、飲食店オーナーなど',
            },
            {
              title: '営業時間',
              description: '9:00〜18:00',
            },
            {
              title: '定休日',
              description: '土曜日、日曜日、祝日、年末年始',
            },
            {
              title: '所在地',
              description: '福岡県北九州市小倉北区上到津4丁目15-1',
            },
            {
              title: 'TEL',
              description: '093-482-6377',
            },
          ]}
        />
        <FeatureSectionLeft
          title="事業内容"
          imageSrc="/corporate/img-business.webp"
          description={`①各種助成金のご案内・申請サポート
          　・キャリアアップ助成金
          　・業務改善助成金
          　・人材確保等支援助成金
          　・両立支援等助成金
          　・高年齢者雇用関係助成金
          　など多数

          ②就業規則・各種規程の策定

          ③採用支援（ハローワーク求人コンサルを含む）

          ④人事評価制度の構築

          ⑤労務サポート（関係官庁への届出・対応を含む）

          ⑥会社設立支援
          　・定款作成など

          ⑦家族法関連業務
          　・相続・遺言
          　・公正証書等の作成等
          ※ＦＰ業務を含む
          `}
        />
      </Layout>
    </div>
  );
};

export default JetsAboutPage;

export const Head = () => (
  <Seo title="JETS社労士事務所" description="会社概要をご紹介します。" />
);
